<script setup>
import ButtonSubmit from "./ButtonSubmit.vue";
import Swal from 'sweetalert2'
import api from "../api/api";
</script>

<template>
    <div class="main-div">
        <div class="bg-main-color1" style="
          padding: 10px;
          color: #fff;
          display: flex;
          justify-content: space-between;
        ">
            <div>
                <strong>{{ label }}</strong>
            </div>
            <button class="bg-main-color1" style="color: #fff" @click.prevent="toggle = !toggle" v-show="!toggle">
                Show
            </button>
            <button class="bg-main-color1" style="color: #fff" @click.prevent="toggle = !toggle" v-show="toggle">
                Hide
            </button>
        </div>
        <div class="parent" v-show="toggle" v-if="pdfs.length == 0">
            <h2>No PDFs available.</h2>
        </div>

        <div class="parent" v-show="toggle" v-if="pdfs.length > 0">
            <div class="child" v-for="pdf in pdfs" :key="pdf">
            <div>
                <h2>{{ cleanPdfName(pdf.name) }}</h2>
                <p>Upload date: {{ formatDate(pdf.modified) }}</p>
            </div>
            <div style="display: flex; justify-content: center; margin: 10px 0;">
                <img class="surveyLogo" src="../../public/PDFlogo.png" style="width: 50%; height: auto;" />
            </div>
            <ButtonSubmit label="Open" style="margin-top: 10px" @click="showPdf(pdf)" />
            <ButtonSubmit label="Delete" style="margin-top: 10px;" @click="showPdf(pdf)" />
            </div>
        </div>

    </div>
</template>

<script>
export default {
    components: [ButtonSubmit],
    name: "HomePDF",
    props: {
        label: String,
        userData: Object,
        brandData: Object,
    },
    data: () => ({
        toggle: true,
        pdfs: [],
        setLoading: false,
        abortController: new AbortController(),  
    }),
    methods: {

        cleanPdfName(name) {
            return name ? name.replace(/\.pdf$/i, '') : ''; // Removes .PDF/.pdf (case-insensitive)
        },

        formatDate(date) {
            return date ? new Date(date).toLocaleString() : "N/A";
        },

        async showPdf(pdf) {
            this.setLoading = true;
            let url = null;
            if (this.setLoading === true) {
                this.showPdfAlert();
            }
            try {
                const requestBody = {
                    ind_id: this.userData.ind_id,
                    pdfName: pdf.name,
                };

                const response = await api.post('/open-pdf-by-user', requestBody, {
                    responseType: 'blob', // Specify the response type as blob,
                    signal: this.abortController.signal,
                });

                // Create a blob URL from the response data
                const blob = new Blob([response.data], { type: 'application/pdf' });
                url = window.URL.createObjectURL(blob);

                if (url !== null) {
                    window.open(url, '_blank');
                    this.setLoading = false;
                    if (this.setLoading === false) {
                        this.hidePdfAlert();
                    }
                }
            } catch (error) {
                if (error.message == 'canceled') {
                    return;
                } 
                else {
                    Swal.fire({
                        title: '',
                        text: 'A pdf is not available. TalentSage Support have been notified and will contact you when the issue has been resolved',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#3085d6',
                        allowOutsideClick: false,
                    });
                    console.error('Error downloading PDF:', error);
                }
            }
        },

        async showPdfAlert() {
            Swal.fire({
                title: "Preparing your pdf, please wait...",
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
        },
        async hidePdfAlert(){
            Swal.close()
        }
    },
    async mounted() {
        try {
            const ind_id = this.userData.ind_id;
            const response = await api.post('/get-pdf-by-user', { ind_id: ind_id });
            this.pdfs = response.data;
        } catch (error) {
            console.error('Error listing PDF files:', error);
        }
    },
};
</script>

<style scoped>
.main-div {
    min-width: 75vw;
    margin: 15px;
}

.parent {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.child {
    border: 1px solid #888;
    border-radius: 10px;
    border-top: 6px solid #0e5071;
    text-align: center;
    font: -webkit-control;
    min-width: 205px;
    max-width: 205px;
    margin: 0 10px;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 2px 4px #ccc;
}

.child:hover {
    border-radius: 2px;
    box-shadow: 5px 8px #ccc;
    transition: all 300ms ease;
}

.surveyLogo {
    width: 150px;
    height: auto;
}

@media only screen and (min-width: 280px) and (max-width: 540px) {
    .main-div {
        margin: 0 5px 0 5px;
    }
}
</style>